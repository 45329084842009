// load sass
import '../sass/common.scss';
// modernizr // webp
// import {modernizer}  from "./common/modernizr.js";
// modernizer();
// // load imageSwitch
// import {imageSwitch} from "./common/sub_imageswtch.js";
// imageSwitch();

// WPA バージョン管理用
const appVersion = '1.0.0';

window.addEventListener('load', () => {

  if (document.getElementsByClassName('js_smart_menu')[0]) {
    document.getElementsByClassName('js_smart_menu')[0].onclick = () => {
      if (document.getElementsByClassName('js_smart_menu')[0].classList.contains('active')) {
        document.getElementsByClassName('js_smart_menu')[0].classList.remove('active')
        document.getElementById('smart-menu').classList.remove('active')
      } else {
        document.getElementsByClassName('js_smart_menu')[0].classList.add('active')
        document.getElementById('smart-menu').classList.add('active')
      }
    }
  }
})
// END SMART CONTENT MENU.
